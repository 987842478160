import React from 'react'
import StorageService from "../../services/localstorage.service";
import useAutoLogout from '../../utils/autoLogout';
import { AUTO_LOGOUT_TIME } from "../../constants/constant";
const Autoloagoutcomponent = (props) => {


    // auto signout user idle after 10 mnts
  const autosignOut = () => {
    //StorageService.clearStore();
    StorageService.removeItem("aptoken");
    StorageService.removeItem("userData");
    StorageService.removeItem("activePortal");
    StorageService.removeItem("activeLaraFilter");
    StorageService.removeItem("activeTurnaroundFilter");
    StorageService.removeItem("reportSort");
    window.location.replace("/login");
  };
//AUTO_LOGOUT_TIME
 const [autoLogout,reset]=useAutoLogout(AUTO_LOGOUT_TIME)
  React.useEffect(() => {
    let apptoken=StorageService.getItem("aptoken");
    if(autoLogout && apptoken){
      // console.log("start logout",autoLogout)
      reset();
      autosignOut();     
}
  }, [autoLogout]);
  return (
    <>
 {props.children}
 </>
    
  )
}

export default Autoloagoutcomponent
