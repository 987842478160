function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", async () => {
      const SW_URL = process.env.PUBLIC_URL + "/serviceworker.js";

      try {
        const registration = await navigator.serviceWorker.register(SW_URL);
        // console.log("Scope:", registration.scope);
      } catch (error) {
        // console.log('ServiceWorker registration failed: ', error);
      }
    });
  }
}

export default registerServiceWorker;
