const MuiContained = {
  backgroundColor: "#191C32",
  color: "#fff",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: "#191C32",
    color: "#fff",
    "@media (hover: none)": {
      backgroundColor: "#191C32",
    },
  },
};

const MuiContainedPrimary = {
  backgroundColor: "#191C32",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#191C32",
    color: "#fff",
    "@media (hover: none)": {
      backgroundColor: "#191C32",
    },
  },
};

const MuiContainedSecondary = {
  backgroundColor: "#F48021",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#F48021",
    color: "#fff",
    "@media (hover: none)": {
      backgroundColor: "#F48021",
    },
  },
};

const MuiButtonOverrides = {
  disableElevation: true,
  contained: MuiContained,
  containedPrimary: MuiContainedPrimary,
  containedSecondary: MuiContainedSecondary,
};

export default MuiButtonOverrides;
