import React from "react";
import { COPY_RIGHT,VERSION_NUMBER } from "../../constants/constant";
import { Typography, Box } from "@material-ui/core";
import  moment from 'moment';
function FooterComponent() {
    const year=moment().format('YYYY');
  return (
    <Box display="flex" justifyContent="center" paddingBottom="1rem" paddingTop="0.5rem" >
      <Typography variant="overline" style={{color:"#6d6d6d"}}>© {year} {COPY_RIGHT} - <span style={{fontStyle:"italic"}}>{VERSION_NUMBER}</span></Typography>
    </Box>
  );
}

export default FooterComponent;
