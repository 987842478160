import React from 'react'
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import AppRoutelist from './app.routelist'
import CustomRouter from './customrouter';
import { LastLocationProvider } from 'react-router-last-location';
import { Box, CircularProgress } from '@material-ui/core';

function AppRouter() {
    const Loader = <Box style={{ zIndex: 999, height: '100%', width: '100%', position: 'fixed', left: 0, top: 0, background: '#ffff', textAlign: 'center' }}>
        <CircularProgress color="primary" style={{ position: 'relative', top: '50%' }} />
    </Box>;

    return (
        <React.Suspense fallback={Loader}>
            <BrowserRouter>
                <LastLocationProvider>
                    <Switch>
                        {AppRoutelist.map((route, index) => {
                            return <CustomRouter
                                auth={route.auth}
                                path={route.path}
                                xcomponent={route.xcomponent}
                                key={route.path + index}
                                rprops={{ ...route }} />
                        })}
                        <Redirect from="*" to="/login" />
                    </Switch>
                </LastLocationProvider>
            </BrowserRouter>
        </React.Suspense >
    )
}

export default AppRouter
