import { SHOW_SNACKBAR, RESET, CLOSE_SNACKBAR } from "../constants/constant";
import ContextTemplate from "./template/template";

const initialState = {
  open: false,
  message: "",
  type: "",
};

//REDUCER

const reducer = (prevstate, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      const { message, type } = action.payload;
      return { ...prevstate, open: true, message, type };
    }

    case CLOSE_SNACKBAR:
      return { ...prevstate, open: false };

    case RESET:
      return initialState;

    default:
      return prevstate;
  }
};

//ACTIONS

const showSnackBar = (dispatch) => (message, type) => {
  const payload = { message, type };

  dispatch({ type: SHOW_SNACKBAR, payload });
};

const closeSnackBar = (dispatch) => () => {
  dispatch({ type: CLOSE_SNACKBAR });
  resetSnackBar(dispatch);
};

const resetSnackBar = (dispatch) => () => dispatch({ type: RESET });

const actions = { showSnackBar, resetSnackBar, closeSnackBar };

//_________________________________

const { Provider, Context } = ContextTemplate(reducer, actions, initialState);

export { Provider, Context };
