import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Typography } from '@material-ui/core';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import useWindowSize from '../../utils/useWindowSize'
const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: '90%',
        background: 'white',
        boxShadow: '-2px 2px 19px -3px rgba(0,0,0,0.75)'
    },
    box: {
        borderTop: '1px slid black',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5,
        alignItems: 'center'
    },
    iframeDiv:{
        padding: 5,
        height:"100%",
        '*::--webkit-overflow-scrolling':  "touch !important",
        overflowY:"scroll !important"
    }
   
}));

export default function Webview({ url, open, handleClose }) {
    const classes = useStyles();
    const size = useWindowSize();
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open} className={classes.paper} >
                <Box style={{ position: 'absolute',top:"0" }}>
                    <Box className={classes.box}>
                        <Typography component="div" variant="h6" color="primary" >Terms & Conditions</Typography>
                        <CancelPresentationIcon onClick={handleClose} color="primary" />
                    </Box>
                    <Box className={classes.iframeDiv}>
                        <div dangerouslySetInnerHTML={{ __html: `<iframe src=${url} width="100%" height="${size.height - 160}"></iframe>` }} />
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}