import React from "react";
import { Redirect, Route } from "react-router-dom";
import StorageService from "../services/localstorage.service";
import HeaderHocContainer from "../common/containers/header.hoc.container";
import Autoloagoutcomponent from "../common/components/autoloagout.component";

const CustomRouter = ({ path, xcomponent, auth, rprops, ...xprops }) => {
  const token = StorageService.getItem("aptoken");
  if (auth === false && token) {
    return <Redirect to="/dashboard"></Redirect>;
  }

  if (!token && auth === true) {
    return <Redirect to="/login" />;
  }
  if (auth === true) {
    if (!rprops.header) {
      return (<Autoloagoutcomponent>
      <Route exact path={path} component={xcomponent} />
      </Autoloagoutcomponent>);
    }
    return (
      <HeaderHocContainer>
       <Autoloagoutcomponent>
        <Route exact path={path} component={xcomponent} />
        </Autoloagoutcomponent>
      </HeaderHocContainer>
    );
  }
  if (!auth) {
    return <Route exact path={path} component={xcomponent} />;
  }
};

export default CustomRouter;
