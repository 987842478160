import React from "react";
import {
  IconButton,
  SwipeableDrawer,
  Typography,
  Button,
  Box,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { Mail, Call, ArrowBack } from "@material-ui/icons";
import { Link as RouteLink } from "react-router-dom";
import StorageService from "../../services/localstorage.service";
import { makeStyles } from "@material-ui/core/styles";
import PlatformComponent from "./platform.component";
import { COPY_RIGHT,VERSION_NUMBER } from "../../constants/constant";
import  moment from 'moment';
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    background: "#034E91",
    paddingBottom: "2rem",
    color: theme.palette.text.secondary,
  },
  drawerContainer: {
    padding: "2rem",
    background: "#034E91",
    color: "#fff",
    height: "inherit",
    overflow: "auto",
  },
  turnaroundImgDiv: {
    width: "2.8rem",
    height: " 2.8rem",
    background: " #eee",
    borderRadius: " 50%",
    display: " flex",
    alignItems: " center",
    justifyContent: " center",
    border: " 4px solid #51ba48",
    padding:"0.2rem"
  },

  upstreamImgDiv: {
    width: "2.8rem",
    height: " 2.8rem",
    background: " #eee",
    borderRadius: " 50%",
    display: " flex",
    alignItems: " center",
    justifyContent: " center",
    border: " 4px solid #ed7d31",
    padding:"0.2rem"
  },
  capitalImgDiv: {
    width: "2.8rem",
    height: " 2.8rem",
    background: " #eee",
    borderRadius: " 50%",
    display: " flex",
    alignItems: " center",
    justifyContent: " center",
    border: " 4px solid  #c00000",
    padding:"0.2rem"
  },
  outrageImgDiv: {
    width: "2.8rem",
    height: " 2.8rem",
    background: " #eee",
    borderRadius: " 50%",
    display: " flex",
    alignItems: " center",
    justifyContent: " center",
    border: " 4px solid #7030a0",
    padding:"0.2rem"
  },

  popupDiv: {
    backgroundColor: "#fff",
    position: "absolute",
    width: "80%",
    padding: "1.5rem 1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    borderRadius: "0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: " url(/assets/popup_bg.png)",
    backgroundRepeat: " no-repeat",
    backgroundPosition: " center",
    backgroundSize: " cover",
    '&:focus':{
      outline:"none"
    }
  },
 

  listWrap: {
    overflow: "overlay",
    marginTop: "0.5rem",
  },
  logoWrap: {
    maxWidth: "3rem",
    minWidth: "2rem",
    marginTop: "0.5rem",
  },

  headerContainer: {
    display: "flex",
    height: "100px",
    padding: "0.5rem",
    backgroundColor: "#034E91",
  
  },
  divider:{
    marginTop:"1.2rem",
    marginBottom:"1.2rem"
  },
  popupClose:{
    color:"#278ecf"
  },
  button:{
fontWeight:"400"
  },
  progress:{
    position:"fixed",
    zIndex:"1000",
    top:"50%",
    transformY:"translate(-50%)"

  },


  root: {
    '& a': {
      padding:"5px",
      fontWeight:"400"
     
    }
  },


}));
function HeaderHocComponent(props) {
  const {
    setShowSideBar,
    showSideBar,
    userName,
    groupName,
    signOut,
    loading,
    viewDashBoard,
    currentPortal
  } = props;
  const userData = JSON.parse(StorageService.getItem("userData"));
  const platformData = userData?.PortalDetails;
  let platformObj = {};
  platformData.forEach((p) => {
    platformObj = { ...platformObj, [p.Portal]: Boolean(p.PortalIsEnabled),[p.Portal+"_id"]: p.PortalId,[p.Portal+"_grpid"]: p.GroupId,[p.Portal+"_roleid"]: p.RoleId, [p.Portal+"_GroupName"]: p.GroupName};
  });
  

  const classes = useStyles();
  const year=moment().format('YYYY');

  return (
    <Box display="flex" className={classes.headerContainer}>
      <Box>
        <IconButton color={"inherit"} onClick={() => setShowSideBar(true)}>
          <Box maxWidth="1.2rem">
            <img
              src="/assets/menu_icon.png"
              alt="menu.png"
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </IconButton>
        <SwipeableDrawer
          anchor="left"
          open={showSideBar}
          onOpen={() => setShowSideBar(true)}
          onClose={() => setShowSideBar(false)}
          swipeAreaWidth={20}
        >
          <div className={classes.drawerContainer}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom="1rem"
            >
              <Typography variant="h6" color="textSecondary">
                Menu
              </Typography>

              <ArrowBack onClick={() => setShowSideBar(false)} />
            </Box>

            <Box component="div" marginBottom="2rem" marginTop="2rem">
              <Typography variant="h6" align="center" color="textSecondary">
                {userName}
              </Typography>

              <Typography
                variant="subtitle2"
                align="center"
                color="textSecondary"
              >
                {groupName}
              </Typography>
            </Box>
            <Box component="div" className={classes.listWrap}>
              <Box component="div" marginTop="0.5rem" marginBottom="0.75rem">
                  <Typography variant="h6" color="textSecondary" onClick={() => setShowSideBar(false)} component="h4">
                    My Dashboard
                  </Typography>
                <Typography variant="caption" component="div">
                  My Active Events & My LARA Status
                </Typography>
              </Box>
              <Divider className={classes.divider}></Divider>

              <Box component="div" marginTop="1rem" marginBottom="0.75rem">
                <RouteLink to="/mylara?overdue=false">
                  <Typography variant="h6" color={"textSecondary"}>
                    My LARA
                  </Typography>
                </RouteLink>

                <Typography variant="caption" component="div">
                  Lessons, Activities, Risks & Actions
                </Typography>
              </Box>
              <Divider className={classes.divider}></Divider>
              <Box component="div" marginTop="1rem" marginBottom="0.75rem">
                <Typography variant="h6" onClick={() => setShowSideBar(false)} color={"textSecondary"}>
                  My Platforms
                </Typography>

                {platformObj["Turnaround Network"] ? (
                  <PlatformComponent
                  onClick={() => viewDashBoard(platformObj["Turnaround Network_id"],platformObj["Turnaround Network_grpid"],platformObj["Turnaround Network_roleid"],platformObj["Turnaround Network_GroupName"])}
                    img="/assets/turnaround_network_colored.png"
                    classname={classes.turnaroundImgDiv}
                    heighlight={currentPortal==platformObj["Turnaround Network_id"]}
                    title="Turnaround Network"
                  ></PlatformComponent>
                ) : (
                  <PlatformComponent
                    img="/assets/turnaround_network_colored.png"
                    classname={classes.turnaroundImgDiv}
                    disabled={true}
                    title="Turnaround Network"
                  ></PlatformComponent>
                )}
                {platformObj["Upstream Network"] ? (
                  <PlatformComponent
                  onClick={() => viewDashBoard(platformObj["Upstream Network_id"],platformObj["Upstream Network_grpid"],platformObj["Upstream Network_roleid"],platformObj["Upstream Network_GroupName"])}
                    img="/assets/upstream_network_colored.png"
                    classname={classes.upstreamImgDiv}
                    heighlight={currentPortal==platformObj["Upstream Network_id"]}
                    title="Upstream Network"
                  ></PlatformComponent>
                ) : (
                  <PlatformComponent
                    img="/assets/upstream_network_colored.png"
                    classname={classes.upstreamImgDiv}
                    disabled={true}
                    title="Upstream Network"
                  ></PlatformComponent>
                )}
                {platformObj["Capital Project Network"] ? (
                  <PlatformComponent
                  onClick={() => viewDashBoard(platformObj["Capital Project Network_id"],platformObj["Capital Project Network_grpid"],platformObj["Capital Project Network_roleid"],platformObj["Capital Project Network_GroupName"])}
                    img="/assets/capital_project_network_colored.png"
                    classname={classes.capitalImgDiv}
                    heighlight={currentPortal==platformObj["Capital Project Network_id"]}
                    title="Capital Project Network"
                  ></PlatformComponent>
                ) : (
                  <PlatformComponent
                    img="/assets/capital_project_network_colored.png"
                    classname={classes.capitalImgDiv}
                    disabled={true}
                    title="Capital Project Network"
                  ></PlatformComponent>
                )}
                {platformObj["Outage Network"] ? (
                  <PlatformComponent
                  onClick={() => viewDashBoard(platformObj["Outage Network_id"],platformObj["Outage Network_grpid"],platformObj["Outage Network_roleid"],platformObj["Outage Network_GroupName"])}
                    img="/assets/outrage_network_colored.png"
                    classname={classes.outrageImgDiv}
                    heighlight={currentPortal==platformObj["Outage Network_id"]}
                    title="Outage Network"
                  ></PlatformComponent>
                ) : (
                  <PlatformComponent
                    img="/assets/outrage_network_colored.png"
                    classname={classes.outrageImgDiv}
                    disabled={true}
                    title="Outage Network"
                  ></PlatformComponent>
                )}
              </Box>
              <Divider className={classes.divider}></Divider>
              <Box component="div" margin="0.75rem 0">
                <Typography
                  variant="h6"
                  color={"textSecondary"}
                >
                  Support
                </Typography>

                    <Box  component="div" marginTop="1rem" marginBottom="0.75rem">
                      <Box display="flex" alignItems="center">
                        <Mail  style={{color:"#fff"}}></Mail>
                        
                        <a
                          href={"mailto:support@ap-networks.com"}
                          style={{ margin: "0.5rem" }}
                        >
                          <Typography variant="button" classes={{button:classes.button}} color={"textSecondary"}>
                            support@ap-networks.com
                          </Typography>
                        </a>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Call  style={{color:"#fff"}}></Call>
                        <a
                          href={"tel:+1.877.303.3581"}
                          style={{ margin: "0.5rem" }}
                        >
                          <Typography variant="button"  classes={{button:classes.button}} color={"textSecondary"}>
                            +1.877.303.3581 (Toll Free)
                          </Typography>
                        </a>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Call  style={{color:"#fff"}}></Call>

                        <a
                          href={"tel:+1.832.240.4986"}
                          style={{ margin: "0.5rem" }}
                        >
                          <Typography variant="button"  classes={{button:classes.button}} color={"textSecondary"}>
                            +1.832.240.4986 (International)
                          </Typography>
                        </a>
                      </Box>
                    </Box>
                   
             
              </Box>
              <Divider className={classes.divider}></Divider>
              <Box component="div" marginTop="1.5rem" textAlign="center">
              
                {!loading ? (

                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={() => {
                      signOut();
                    }}
                  >
                    Sign Out
                  </Button>
                ) : (
                 
                   <CircularProgress className={classes.progress} color="primary" />
               )} 
              </Box>
              <Box component="div" marginTop="1rem" textAlign="center">
              <Typography variant="overline"  style={{color:"#fff"}}>© {year} {COPY_RIGHT} - <span style={{fontStyle:"italic"}}>{VERSION_NUMBER}</span></Typography>
              </Box>
              
            </Box>
          </div>
        </SwipeableDrawer>
      </Box>
      <Box marginLeft="auto">
        <div className={classes.logoWrap}>
          <img
            src="/assets/login_logo.png"
            alt="logo.png"
            style={{ maxWidth: "100%" }}
          ></img>
        </div>
      </Box>
    </Box>
  );
}

export default HeaderHocComponent;
