import { SET_REPORTSORT, CLEAR_REPORTSORT } from "../constants/constant";
import ContextTemplate from "./template/template";
import LocalStorageService from "../services/localstorage.service";

const initialState = JSON.parse(LocalStorageService.getItem("reportSort")) || {};

//REDUCER
const reducer = (state, action) => {
    switch (action.type) {
        case CLEAR_REPORTSORT: {
            return {};
        }
        case SET_REPORTSORT:
            LocalStorageService.storeItem("reportSort", JSON.stringify({ ...state, ...action.payload }));
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
//---------------ACTIONS-------------
const clearReportSort = (dispatch) => () => {
    dispatch({ type: CLEAR_REPORTSORT });
};
const setReportSort = (dispatch) => (payload) => {
    dispatch({ type: SET_REPORTSORT, payload });
};
const actions = { clearReportSort, setReportSort };
//-------------ACTIONS ----------
const { Provider, Context } = ContextTemplate(reducer, actions, initialState);
export { Provider, Context };
