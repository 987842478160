import React from "react";

import LoginContainer from "../modules/login/containers/Login.container";
const DashboardReportContainer = React.lazy(() =>
  import("../modules/reports/containers/dashboardreport.container")
);

const IndividualOwnerReportContainer = React.lazy(() =>
  import("../modules/reports/containers/individualowner.report.container")
);

const SteeringTeamReportsContainer = React.lazy(() =>
  import("../modules/reports/containers/steeringteamreport.container")
);

const DashboardContainer = React.lazy(() =>
  import("../modules/dashboard/containers/Dashboard.container")
);
const AccountLockedContainer = React.lazy(() =>
  import("../modules/login/containers/AccountLocked.container")
);
const ForgotPasswordContainer = React.lazy(() =>
  import("../modules/login/containers/ForgotPassword.container")
);
const ForgotUsernameContainer = React.lazy(() =>
  import("../modules/login/containers/ForgotUsername.container")
);

const TermsConditionContainer = React.lazy(() =>
  import("../modules/login/containers/TermsCondition.container")
);

const MyLaraContainer = React.lazy(() =>
  import("../modules/mylara/containers/mylara.container")
);

const MyTurnaroundContainer = React.lazy(() =>
  import("../modules/myturnaround/containers/allmyturnaround.containers")
);

const TurnaroundDetailContainer = React.lazy(() =>
  import("../modules/myturnaround/containers/turnarounddetail.container")
);

const PasswordSettingsContainer = React.lazy(() =>
  import("../modules/password/containers/password.settings.containers")
);

const ActivityDetailsContainer = React.lazy(() =>
  import("../modules/detailsscreen/containers/activitydetails.container")
);

const RiskItemContainer = React.lazy(() =>
  import("../modules/detailsscreen/containers/riskitemdetails.container")
);

const ActionItemContainer = React.lazy(() =>
  import("../modules/detailsscreen/containers/actionitemDetails.container")
);

const LessonDetailContainer = React.lazy(() =>
  import("../modules/detailsscreen/containers/lessonsdetails.container")
);

const HelperSliderComponent = React.lazy(() =>
  import("../modules/login/components/helper.slider.component")
);

const AppRoutelist = [
  {
    path: "/login",
    xcomponent: LoginContainer,
    permissions: [],
    auth: false,
    header: false,
  },

  {
    path: "/forgot-username",
    xcomponent: ForgotUsernameContainer,
    permissions: [],
    auth: false,
    header: false,
  },

  {
    path: "/forgot-password",
    xcomponent: ForgotPasswordContainer,
    permissions: [],
    auth: false,
    header: false,
  },

  {
    path: "/account-locked",
    xcomponent: AccountLockedContainer,
    permissions: [],
    auth: false,
    header: false,
  },
  {
    path: "/dashboard",
    xcomponent: DashboardContainer,
    permissions: [],
    auth: true,
    header: true,
  },
  {
    path: "/mylara",
    xcomponent: MyLaraContainer,
    permissions: [],
    auth: true,
    header: false,
  },
  {
    path: "/myturnaround",
    xcomponent: MyTurnaroundContainer,
    permissions: [],
    auth: true,
    header: false,
  },

  {
    path: "/verify/password",
    xcomponent: PasswordSettingsContainer,
    permissions: [],
    auth: false,
    header: false,
  },

  {
    path: "/myturnarounddetail/:eventName/:eventId",
    xcomponent: TurnaroundDetailContainer,
    permissions: [],
    auth: true,
    header: false,
  },
  {
    path: "/dashboard-reports/:eventName/:eventId",
    xcomponent: DashboardReportContainer,
    permissions: [],
    auth: true,
    header: false,
  },
  {
    path: "/individualowner-reports/:eventName/:eventId",
    xcomponent: IndividualOwnerReportContainer,
    permissions: [],
    auth: true,
    header: false,
  },
  {
    path: "/steeringteam-reports/:eventName/:eventId",
    xcomponent: SteeringTeamReportsContainer,
    permissions: [],
    auth: true,
    header: false,
  },
  {
    path: "/activity-details/:taskId",
    xcomponent: ActivityDetailsContainer,
    permissions: [],
    auth: true,
    header: false,
  },
  {
    path: "/risk-details",
    xcomponent: RiskItemContainer,
    permissions: [],
    auth: true,
    header: false,
  },
  {
    path: "/action-details",
    xcomponent: ActionItemContainer,
    permissions: [],
    auth: true,
    header: false,
  },
  {
    path: "/lesson-details",
    xcomponent: LessonDetailContainer,
    permissions: [],
    auth: true,
    header: false
  },
  {
    path: "/tnc",
    xcomponent: TermsConditionContainer,
    permissions: [],
    auth: false,
    header: false
  },
  {
    path: "/helptour",
    xcomponent: HelperSliderComponent,
    permissions: [],
    auth: false,
    header: false
  }
];

export default AppRoutelist;
