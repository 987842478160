import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import Footer from "../../../common/components/footer.component"
import Webview from "../../../common/components/webview";

const useStyle = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    background: 'white',
    height: "100%",
    justifyContent:"space-between",
    '@media (max-height:399px)': {
      height: "auto",
    },
  },
  header: {
    position: "relative",
    background: "#024a85 url('/assets/log-header.png') no-repeat center bottom",
    width: "100%",
    backgroundSize: "contain",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "300px",
    minHeight: "180px",
    '@media (max-width:600px)': {
      height: "200px",
      backgroundSize: "contain",
    },
    '@media (min-width:768px) and (min-height:400px)': {
      height: "300px",
      backgroundSize: "contain",
    },
    '@media (min-width:768px) and (max-height:399px)': {
      height: "210px",
      backgroundSize: "contain",
    },
    '@media (min-width:1023px)': {
      height: "500px",
      backgroundSize: "contain",
    },
    '@media (min-width:1367px)': {
      background: "#ffffff url('/assets/log-header.png') no-repeat center bottom",
      height: "650px",
      backgroundSize: "contain",
    }
  },
  headerLogo: {
    maxHeight: "40%",
    marginTop: "-10%",
    '@media (min-width:768px)': {
      maxHeight: "100px",
    },
    '@media (min-width:768px) and (max-height:399px)': {
      maxHeight: "30%",
    },
  },
  layoutImg: {
    background: "#ffffff url('/assets/login_footer.png') no-repeat center bottom",
    backgroundSize: "contain",
  },
}));

function LoginLayoutHOC(props) {
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img src="/assets/login_logo.png" className={classes.headerLogo} alt="" />
      </div>

      <Grid>
        {/* <Grid xs={1} sm={2} item></Grid> */}
        <Grid xs={12}  item style={{ padding: "1rem 2rem" }}>
          {props.children}
        </Grid>
        {/* <Grid xs={1} sm={2} item></Grid> */}
      </Grid>

      <Box component="div" textAlign="center" className={classes.layoutImg}>
        <Typography
          component="span"
          style={{ marginLeft: "0.5rem", cursor: 'pointer' }}
          variant="button"
        >
          <span onClick={handleOpen}>Terms & Conditions</span>
        </Typography>
        <Footer />
      </Box>
      <Webview {...{ open, handleClose, url: "https://mobile.ap-networks.com/terms.html" }} />
    </div>
  );
}

export default LoginLayoutHOC;
