import { SHOW_LOADER, CLOSE_LOADER } from "../constants/constant";
import ContextTemplate from "./template/template";

const initialState = {
    show: false
};

//REDUCER
const reducer = (prevstate, action) => {
    switch (action.type) {
        case SHOW_LOADER: {
            return { show: true };
        }

        case CLOSE_LOADER:
            return { show: false };

        default:
            return prevstate;
    }
};

//---------------ACTIONS-------------
const showLoader = (dispatch) => (message, type) => {
    const payload = { message, type };

    dispatch({ type: SHOW_LOADER, payload });
};

const closeLoader = (dispatch) => () => {
    dispatch({ type: CLOSE_LOADER });
};


const actions = { showLoader, closeLoader };

//-------------ACTIONS ----------

const { Provider, Context } = ContextTemplate(reducer, actions, initialState);

export { Provider, Context };
