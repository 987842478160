import axios from "axios";
import React from "react";
import { Context as SnackbarContext } from "../context/snackbar.context";
import HttpService from "./http.service";

function useHttpApiCall(payload) {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const toastContext = React.useContext(SnackbarContext);

  const { showSnackBar } = toastContext;

  React.useEffect(() => {
    let componentMounted = true;
    const cancelToken = axios.CancelToken.source();

    const ApiCall = async () => {
      if (payload) {
        try {
          setLoading(true);
          const data = await HttpService.fetch(payload, cancelToken);
          if (componentMounted) {
            setLoading(false);
            setError("");
            setData(data);
          }
        } catch (err) {
          console.log(err);
          setData(null);
          setLoading(false);
          setError(err && err.Data ? err.Data : err);
          let msgObj;

          if (err && typeof err.Message === "string") {
            msgObj = { type: "error", message: err.Message };
          } else if (err && typeof err.Message === "object") {
            msgObj = { type: "error", message: err.Message[0] };
          } else {
            msgObj = {
              type: "error",
              message:
                "The application has encountered an error, please contact the administrator.",
            };
          }
          showSnackBar(msgObj.message, msgObj.type);
        }
      }
    };

    ApiCall();

    return () => {
      componentMounted = false;
      cancelToken.cancel("Request Cancelled!");
    };
  }, [payload]);

  return [data, loading, error];
}

export default useHttpApiCall;
