import React from 'react'
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles(() => ({
  container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    header: {
      position: "relative",
    },
    layoutImg: {
      maxWidth: "100%",
      width: "100%",
      height: "auto",
    },
}));

function SplashscreenComponent() {
    const classes = useStyle();
    
  return (
    <div className={classes.container}>
        <div className={classes.header}>
        <img src='/APN-APP-Splashscreen-v7.png' className={classes.layoutImg}/>
        </div>
    </div>
  )
}

export default SplashscreenComponent
