import React from "react";
import useHttpApiCall from "../../../services/useHttpApiCall";
import { isRequiredCheck } from "../../../utils/formValidator";
import LoginComponent from "../components/Login.component";
import StorageService from "../../../services/localstorage.service";

import { Base64 } from "js-base64";
import { useHistory } from "react-router-dom";
import { Context as SnackbarContext } from "../../../context/snackbar.context";
import useQuery from "../../../utils/routequeryparam";

import { Context as PortalContext } from "../../../context/portal.context";

function LoginContainer() {
  const [formdata, setFormdata] = React.useState({
    username: "",
    password: "",
    platform: 0,
  });
  const [remember, setRemember] = React.useState(false);
  const [error, setError] = React.useState({
    username: "",
    password: "",
    platform: "",
  });

  const [payload, setPayload] = React.useState(null);
  const [unlockpayload, setUnlockPayload] = React.useState(null);
  const [unlockFromMail, setunlockFromMail] = React.useState(null);
  const [platformpayload, setPlatformPayload] = React.useState(null);
  const [platformdata, setPlatformData] = React.useState([]);

  const toastContext = React.useContext(SnackbarContext);
  const { showSnackBar } = toastContext;
  const history = useHistory();
  const [data, loading, responseerror] = useHttpApiCall(payload);
  const [Udata] = useHttpApiCall(unlockpayload);
  const [Pdata, Ploading] = useHttpApiCall(platformpayload);
  let query = useQuery();

  const portalcontext = React.useContext(PortalContext);
  // Sign in Response suucess Use effect
  React.useEffect(() => {
    if (data && data.data) {
      const { token, response } = data.data;
      if (response?.Data?.IsAccepted === false) {
        history.push("/tnc", { token, response, formdata, remember });
      } else {
        StorageService.storeItem("aptoken", token);
        const encryptPaswprd = Base64.encode(formdata.password);
        const encryptUsername = formdata.username;
        const selectedPortalId = formdata.platform;
        const userCred = {
          username: encryptUsername,
          password: encryptPaswprd,
          portalid: selectedPortalId,
        };
        if (remember) {
          StorageService.storeItem("usercreditional", JSON.stringify(userCred));
        } else {
          StorageService.removeItem("usercreditional");
        }
        const pDetails = response.Data.PortalDetails
          ? response.Data.PortalDetails
          : [];
        const userObj = {
          portalId: response.Data.PortalID,
          FirstName: response.Data.FirstName,
          LastName: response.Data.LastName,
          Email: response.Data.LastName,
          UserId: response.Data.UserId,
          RoleId: response.Data.RoleId,
          GroupName: response.Data.GroupName,
          GroupId: response.Data.GroupId,
          IsFirstTimeUser: response.Data.IsFirstTimeUser,
          PortalDetails: pDetails,
        };

        StorageService.storeItem("userData", JSON.stringify(userObj));

        var portalId = userObj.portalId;
        var groupId = userObj.GroupId;
        var roleId = userObj.RoleId;

        let portObj = {
          portalId: portalId,
          groupId: groupId,
          roleId: roleId,
          GroupName: userObj.GroupName
        }
        portalcontext.setPortalDetails(portObj);

        history.push("/dashboard");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, formdata]);

  // Sign in Response error Use effect
  React.useEffect(() => {
    if (responseerror) {
      showSnackBar(responseerror.Message, "error");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseerror]);

  // Setting User creditional for remember me

  React.useEffect(() => {
    const userCred = JSON.parse(StorageService.getItem("usercreditional"));
    if (userCred) {
      const uPassword = Base64.decode(userCred.password);
      const uUsername = userCred.username;
      const uPortalid = userCred.portalid;
      setFormdata({
        username: uUsername,
        password: uPassword,
        platform: uPortalid,
      });
      setRemember((prev) => !prev);
    }
    const unlockmail = query.get("unlockfromemail");

    if (unlockmail) {
      setunlockFromMail(unlockmail);
    }

    setPlatformPayload({
      method: "POST",
      auth: false,
      url: "/portal/getportals",
    });
  }, []);

  // Unlock Account User UseEffect Starts

  React.useEffect(() => {
    if (unlockFromMail) {
      let senddata = {
        authtoken: unlockFromMail,
      };
      setUnlockPayload({
        method: "POST",
        data: senddata,
        auth: false,
        url: "/user/unlockuser",
      });
    }
  }, [unlockFromMail]);

  React.useEffect(() => {
    if (Udata && Udata.data) {
      showSnackBar(Udata.data.Message, "success");
    }
  }, [Udata]);

  // Unlock Account User UseEffect Ends

  React.useEffect(() => {
    if (Pdata && Pdata.data) {
      setPlatformData(Pdata.data.Events);
    }
  }, [Pdata]);

  const toggleRememberMe = () => {
    setRemember((prev) => !prev);
  };

  const handleLoginForm = (e) => {
    const { name, value } = e.target;
    setFormdata((prev) => ({ ...prev, [name]: value }));
  };

  const formSubmit = (e) => {
    e.preventDefault();
    let isError = false;
    for (let key in formdata) {
      const error = isRequiredCheck(formdata[key], key);
      if (error) isError = true;
      setError((prev) => ({ ...prev, [key]: error }));
    }

    if (!isError) {
      //  Continue with the LoginAction

      let senddata = {
        username: formdata.username,
        password: formdata.password,
        portalid: formdata.platform,
      };
      setPayload({
        data: senddata,
        url: "/user/authenticate",
        method: "POST",
      });
    }
  };

  return (
    <>
      <LoginComponent
        formdata={formdata}
        remember={remember}
        handleLoginForm={handleLoginForm}
        formSubmit={formSubmit}
        error={error}
        toggleRememberMe={toggleRememberMe}
        loading={loading}
        platformdata={platformdata}
        Ploading={Ploading}
      />
    </>
  );
}

export default LoginContainer;
