import React from "react";
import HeaderHocComponent from "../components/header.hoc.component";
import useHttpApiCall from "../../services/useHttpApiCall";
import LocalStorageService from "../../services/localstorage.service";
import { useHistory } from "react-router-dom";
import { Context as PortalContext } from "../../context/portal.context";
function HeaderHocContainer(props) {
  const [showSideBar, setShowSideBar] = React.useState(false);

  const userData = JSON.parse(LocalStorageService.getItem("userData"));
  const userName = userData?.FirstName + " " + userData?.LastName;
  const groupName = userData?.GroupName;
  const [payload, setPayload] = React.useState(null);
  const [data, loading] = useHttpApiCall(payload);
  const history = useHistory();
  const portalcontext = React.useContext(PortalContext);
  const {
    state: { portalId },
  } = portalcontext;
  React.useEffect(() => {
    if (data && data.data) {
      // Handle Login Response Logic
      LocalStorageService.removeItem("aptoken");
      LocalStorageService.removeItem("userData");

      LocalStorageService.removeItem("activePortal");
      LocalStorageService.removeItem("activeLaraFilter");
      LocalStorageService.removeItem("activeTurnaroundFilter");
      LocalStorageService.removeItem("reportSort");
      
      history.push("/login");
    }
  }, [data]);

  const signOut = () => {
    setPayload({
      auth: true,
      url: "/user/logout",
      method: "GET",
    });
  };
 

  const viewDashBoard = (evId,grpId,roleId, GroupName) => {
    setShowSideBar(false);
    let portObj={
      portalId:evId,
      groupId:grpId,
      roleId:roleId,
      GroupName: GroupName
    }
 
    portalcontext.setPortalDetails(portObj);
    history.push("/dashboard");
  };
  return (
    <>
      <HeaderHocComponent
        setShowSideBar={setShowSideBar}
        showSideBar={showSideBar}
        userName={userName}
        groupName={groupName}
        signOut={signOut}
        loading={loading}
        viewDashBoard={viewDashBoard}
        currentPortal={portalId}
      ></HeaderHocComponent>
      {props.children}
    </>
  );
}

export default HeaderHocContainer;

