import axios from "axios";
import configurl from "../config/configUrl";
import StorageService from "./localstorage.service";
const RequestConfigHandle = async (config) => {
  return config;
};

const ResponseConfigHandle = async (config) => {
  return config;
};

const RequestErrorHandle = async (error) => {
  return Promise.reject(error);
};

const ResponseErrorHandle = async (error) => {
  if (axios.isCancel(error)) {
    return;
  }
  if (error && error.request && error.request.status === 401) {
    // Clear token from Local Storage and reload the window or we can handle the Refresh token logic to fetch new token

      StorageService.clearStore();
      window.location.replace("/login");


    return Promise.reject(error.response.data);
  } else if (error.response === undefined) {
    return Promise.reject(undefined);
  } else if (error && error.response) {
    return Promise.reject(error.response.data);
  } else return Promise.reject(error);
};

class HttpServiceSingleton {
  constructor() {
    this.reqInterceptors = null;
    this.resInterceptors = null;
    this.Axios = axios.create();
    this.Axios.defaults.headers.get["Accept"] = "applicaton/json";
    this.Axios.defaults.headers.post["Content-Type"] = "application/json";
    this.Axios.defaults.headers.put["Content-Type"] = "application/json";
    this.Axios.defaults.headers.patch["Content-Type"] = "application/json";
    this.Axios.defaults.headers.post["Accept"] = "application/json";
    this.Axios.defaults.headers.put["Accept"] = "application/json";
    this.Axios.defaults.headers.patch["Accept"] = "application/json";
  }

  fetch = async (payload, cancelToken = null) => {
    try {
      const config = {};
      const apToken = StorageService.getItem("aptoken");
      config.data = payload.data ? JSON.stringify(payload.data) : {};
      config.headers = payload.fileUpload
        ? { "Content-Type": "multipart/form-data" }
        : { "Content-Type": "application/json" };
      config.headers = payload.auth
        ? { ...config.headers, Authorization: apToken }
        : { ...config.headers };
      config.method = payload.method ? payload.method : "GET";
      config.url = payload.url
        ? configurl.baseUrl + payload.url
        : configurl.baseUrl;
      config.params = payload.params ? payload.params : {};
      config.cancelToken = cancelToken.token;
      config.timeout= 180000;
      const data = await this.Axios(config);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("API cancelled", error);
      } else {
        throw error;
      }
    }
  };

  httpInterceptors() {
    this.reqInterceptors = this.Axios.interceptors.request.use(
      RequestConfigHandle,
      RequestErrorHandle
    );
    this.resInterceptors = this.Axios.interceptors.response.use(
      ResponseConfigHandle,
      ResponseErrorHandle
    );
  }
}

const HttpService = new HttpServiceSingleton();

export default HttpService;
