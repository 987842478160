import { useState,useRef, useEffect } from 'react';
import StorageService from "../services/localstorage.service";
const CHECK_INTERVAL = 1000; // in ms

export default function useAutoLogout(MINUTES_UNITL_AUTO_LOGOUT) {
    const [isTimeout, setisTimeout] = useState(false);
    const lastAction = useRef(Date.now());
    const clearInter = useRef();
    let apptoken=StorageService.getItem("aptoken");
    useEffect(() => {
            check();
            initListener();
            initInterval();
       
        
        return() => {
            document.body.removeEventListener("click", () => reset());
            if(clearInter.current){
                clearInterval(clearInter.current);
            }
           
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initListener = () => document.body.addEventListener("click", () => reset());

    const reset = () => {
        lastAction.current=Date.now();
        setisTimeout(false);
    };

    const initInterval = () =>{
        clearInter.current = setInterval(() => check(), CHECK_INTERVAL);
    } 


    const check = () => {
        const now = Date.now();
        const timeleft = lastAction.current + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now; const isTimeout = diff < 0;
         //console.log(diff)
        if (isTimeout) {
            setisTimeout(true);
        }
    }
    return [isTimeout,reset];
}