var baseUrl;

if (process.env.REACT_APP_ENV == "dev") {
  baseUrl = "http://192.168.1.18:808/api";
  //baseUrl = "http://192.168.21.63:80/api";
 
} else {
  baseUrl = "https://api.ap-networks.com/api";
}
const ConfigUrl = {
  baseUrl,
};

export default ConfigUrl;
