const SHOW_SNACKBAR = "SHOW_SNACKBAR";
const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
const RESET = "RESET";

const SHOW_LOADER = "SHOW_LOADER";
const CLOSE_LOADER = "CLOSE_LOADER";

const GET_PORTALDETAILS = "GET_PORTALDETAILS";
const SET_PORTALDETAILS = "SET_PORTALDETAILS";

const VERSION_NUMBER = "v1.0.0";
const COPY_RIGHT = "Asset Performance Networks, LLC.";
const SET_LARAFILTER = "SET_LARAFILTER";
const CLEAR_LARAFILTER = "CLEAR_LARAFILTER";
const SET_TURNAROUNDFILTER = " SET_TURNAROUNDFILTER";
const CLEAR_TURNAROUNDFILTER = "CLEAR_TURNAROUNDFILTER";
const SET_REPORTSORT = "SET_REPORTSORT";
const CLEAR_REPORTSORT = "CLEAR_REPORTSORT";
const APP_URL = "https://mobile.ap-networks.com/";
const AUTO_LOGOUT_TIME = 10;

export { SHOW_SNACKBAR, RESET, CLOSE_SNACKBAR, SHOW_LOADER, CLOSE_LOADER, VERSION_NUMBER, COPY_RIGHT, GET_PORTALDETAILS, CLEAR_REPORTSORT, SET_REPORTSORT, SET_PORTALDETAILS, SET_LARAFILTER, CLEAR_LARAFILTER, SET_TURNAROUNDFILTER, CLEAR_TURNAROUNDFILTER,APP_URL,AUTO_LOGOUT_TIME };
