import React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link as RouteLink } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import LoginLayoutHOC from "../hoc/loginlayout.hoc";

function LoginComponent(props) {
  const {
    formdata,
    remember,
    handleLoginForm,
    formSubmit,
    error,
    toggleRememberMe,
    loading,
    platformdata,
    Ploading,
  } = props;
  return (
    <>
      <LoginLayoutHOC>
        <Typography component="h1" variant="h4" align="center">
          Sign In
          </Typography>
        <form
          onSubmit={formSubmit}
          style={{ width: "100%", marginTop: "1rem"}}
         
        >
          <Box display="flex" justifyContent="flex-end" marginBottom="0.5rem">
            <RouteLink to="/forgot-username">
              <Typography variant="button">Forgot Username?</Typography>
            </RouteLink>
          </Box>

          <Box marginBottom="1.5rem">
            <TextField
              autoComplete={""}
              label="Username"
              value={formdata.username}
              onChange={handleLoginForm}
              disabled={loading}
              name="username"
              fullWidth={true}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            {error.username ? (
              <Typography
                component="div"
                align="center"
                variant="caption"
                color="error"
              >
                {error.username}
              </Typography>
            ) : null}
          </Box>

          <Box display="flex" justifyContent="flex-end" marginBottom="0.5rem">
            <RouteLink to="/forgot-password">
              <Typography variant="button">Forgot Password?</Typography>
            </RouteLink>
          </Box>

          <Box marginBottom="1rem">
            <TextField
              autoComplete={""}
              label="Password"
              type="password"
              value={formdata.password}
              onChange={handleLoginForm}
              disabled={loading}
              name="password"
              fullWidth={true}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            {error.password ? (
              <Typography
                component="div"
                align="center"
                variant="caption"
                color="error"
              >
                {error.password}
              </Typography>
            ) : null}
          </Box>
          <Box>
            <InputLabel id="demo-simple-select-label">
              <Typography variant="overline">Select Platform</Typography>
            </InputLabel>
            {!Ploading ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={loading}
                fullWidth={true}
                value={formdata.platform}
                onChange={handleLoginForm}
                name="platform"
                variant="outlined"
                style={{ marginTop: "0.5rem" }}
                placeholder="Select Platform"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value={0}>Select Platform</MenuItem>
                {platformdata.map((item, keyIndex) => {
                  return (
                    <MenuItem
                      key={item.Portalid + keyIndex}
                      value={item.Portalid}
                     
                    >
                      {item.Portal}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              <Box display="flex" justifyContent="center" marginY="1rem">
                <CircularProgress color="primary" />
              </Box>
            )}

            {error.platform ? (
              <Typography
                component="div"
                align="center"
                variant="caption"
                color="error"
              >
                {error.platform}
              </Typography>
            ) : null}
          </Box>
          <Box></Box>
          <Box display="flex" alignItems="center" marginTop="1rem">
            <Checkbox
              color="primary"
              name="remember"
              onChange={toggleRememberMe}
              checked={remember}
              disabled={loading}
              style={{ padding: "0px" }}
            />
            <Typography component="span" variant="button">
              Remember Me
              </Typography>
          </Box>
          <Box display="flex" justifyContent="center" marginY="1rem">
            {!loading ? (
              <Button onClick={formSubmit} variant="contained" size={"large"}>
                Sign In
              </Button>
            ) : (
              <CircularProgress color="primary" />
            )}
          </Box>
          <Box display="flex" justifyContent="center">
            <RouteLink to="/account-locked">
              <Typography variant="button">Locked Out?</Typography>
            </RouteLink>
          </Box>
        </form>

      </LoginLayoutHOC>
    </>
  );
}

export default LoginComponent;
