import { GET_PORTALDETAILS, SET_PORTALDETAILS } from "../constants/constant";
import ContextTemplate from "./template/template";
import LocalStorageService from "../services/localstorage.service";
var activePortal = JSON.parse(LocalStorageService.getItem("activePortal"));
var initialState;
if (activePortal && activePortal.portalId && activePortal.groupId) {
  initialState = {
    portalId: activePortal.portalId,
    groupId: activePortal.groupId,
    roleId:activePortal.roleId,
    GroupName:activePortal.GroupName
  };
} else {
  initialState = {
    portalId: "",
    groupId: "",
    roleId:"",
    GroupName: ""
  };
}

//REDUCER
const reducer = (prevstate, action) => {
  switch (action.type) {
    case GET_PORTALDETAILS: {
      return { ...prevstate };
    }

    case SET_PORTALDETAILS:
      LocalStorageService.storeItem(
        "activePortal",
        JSON.stringify(action.payload)
      );
      return { ...prevstate, ...action.payload };

    default:
      return prevstate;
  }
};

//---------------ACTIONS-------------
const getPortalDetails = (dispatch) => () => {
  dispatch({ type: GET_PORTALDETAILS });
};

const setPortalDetails = (dispatch) => (payload) => {
  dispatch({ type: SET_PORTALDETAILS, payload });
};

const actions = { getPortalDetails, setPortalDetails };

//-------------ACTIONS ----------

const { Provider, Context } = ContextTemplate(reducer, actions, initialState);

export { Provider, Context };
