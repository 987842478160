import MuiButtonOverrides from "./overrides/MButton";
import MCheckbox from "./overrides/MCheckBox";
import palette from "./palette";
import typography from "./typogrpahy";

const { createMuiTheme, responsiveFontSizes } = require("@material-ui/core");

let theme = createMuiTheme({
  palette: palette,
  typography: typography,
  overrides: {
    MuiButton: MuiButtonOverrides,
    MuiCheckbox: MCheckbox,
    MuiInputLabel: {
      root: {
        color: "#777",
      },
    },

    MuiFormLabel: {
      root: {
        "&$focused": {
          color: palette.primary.light,
        },
      },
      colorSecondary: {
        color: palette.secondary.light,
      },
    },
  },
  props: {
    MuiButton: {},
  },
  shape: {
    borderRadius: 3,
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 2000,
    tooltip: 1500,
  },
  spacing: 8,
});

theme = responsiveFontSizes(theme, {
  breakpoints: ["xs", "sm", "md", "lg"],
  disableAlign: true,
  factor: 3,
});

export default theme;
