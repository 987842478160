import palette from "./palette";

const fontFamily = "Arial, sans-serif";
const typography = {
  htmlFontSize: 10,
  fontFamily: fontFamily,
  fontSize: 10,
  fontWeightLight: 100,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 900,
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "4rem",
    letterSpacing: "-0.24px",
    lineHeight: "40px",
    fontFamily,
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "3rem",
    letterSpacing: "-0.24px",
    lineHeight: "3.2rem",
    fontFamily,
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "2.8rem",
    letterSpacing: "-0.06px",
    lineHeight: "28px",
    fontFamily,
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "2.5rem",
    letterSpacing: "-0.06px",
    lineHeight: "24px",
    fontFamily,
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "2rem",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
    fontFamily,
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "1.5rem",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
    fontFamily,
  },
  subtitle1: {
    color: palette.primary.main,
    fontSize: "1.15rem",
    fontWeight: 700,
    letterSpacing: "-0.05px",
    lineHeight: "25px",
    fontFamily,
  },
  subtitle2: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: "1.1rem",
    letterSpacing: "-0.05px",
    lineHeight: "21px",
    fontFamily,
  },
  body1: {
    color: palette.text.primary,
    fontSize: "1rem",
    letterSpacing: "0.5px",
    lineHeight: "21px",
    fontFamily,
  },
  body2: {
    color: palette.text.primary,
    fontSize: "0.9rem",
    letterSpacing: "0.5px",
    lineHeight: "1.2rem",
    fontFamily,
  },
  button: {
    color: palette.primary.light,
    fontSize: "0.95rem",
    textTransform: "none",
    fontFamily,
  },
  caption: {
    color: "#51ba48",
    fontSize: "0.8rem",
    letterSpacing: "0.330px",
    lineHeight: "1.2rem",
    fontFamily,
  },
  overline: {
    color: "#aaa",
    fontSize: "0.75rem",
    fontWeight: 500,
    letterSpacing: "0.33px",
    lineHeight: "1rem",
    textTransform: "none",
    fontFamily,
  },
};

export default typography;
