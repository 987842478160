import { SET_LARAFILTER, CLEAR_LARAFILTER } from "../constants/constant";
import ContextTemplate from "./template/template";
import LocalStorageService from "../services/localstorage.service";
var activeLaraFilter = JSON.parse(
  LocalStorageService.getItem("activeLaraFilter")
);
var initialState;
if (activeLaraFilter) {
  initialState = activeLaraFilter;
} else {
  initialState = {};
}
//REDUCER
const reducer = (prevstate, action) => {
  switch (action.type) {
    case CLEAR_LARAFILTER: {
      return { ...prevstate };
    }
    case SET_LARAFILTER:
      LocalStorageService.storeItem(
        "activeLaraFilter",
        JSON.stringify({ ...prevstate, ...action.payload })
      );
      return { ...prevstate, ...action.payload };
    default:
      return prevstate;
  }
};
//---------------ACTIONS-------------
const clearLaraFilter = (dispatch) => () => {
  dispatch({ type: CLEAR_LARAFILTER });
};
const setLaraFilter = (dispatch) => (payload) => {
  dispatch({ type: SET_LARAFILTER, payload });
};
const actions = { clearLaraFilter, setLaraFilter };
//-------------ACTIONS ----------
const { Provider, Context } = ContextTemplate(reducer, actions, initialState);
export { Provider, Context };
