import { SET_TURNAROUNDFILTER, CLEAR_TURNAROUNDFILTER } from "../constants/constant";
import ContextTemplate from "./template/template";
import LocalStorageService from "../services/localstorage.service";
var activeTurnaroundFilter = JSON.parse(
  LocalStorageService.getItem("activeTurnaroundFilter")
);
var initialState;
if (activeTurnaroundFilter) {
  initialState = activeTurnaroundFilter;
} else {
  initialState = {};
}
//REDUCER
const reducer = (prevstate, action) => {
  switch (action.type) {
    case CLEAR_TURNAROUNDFILTER: {
      return { ...prevstate };
    }
    case SET_TURNAROUNDFILTER:
      LocalStorageService.storeItem(
        "activeTurnaroundFilter",
        JSON.stringify({ ...prevstate, ...action.payload })
      );
      return { ...prevstate, ...action.payload };
    default:
      return prevstate;
  }
};
//---------------ACTIONS-------------
const clearTurnaroundFilter = (dispatch) => () => {
  dispatch({ type: CLEAR_TURNAROUNDFILTER });
};
const setTurnaroundFilter = (dispatch) => (payload) => {
  dispatch({ type: SET_TURNAROUNDFILTER, payload });
};
const actions = { clearTurnaroundFilter, setTurnaroundFilter };
//-------------ACTIONS ----------
const { Provider, Context } = ContextTemplate(reducer, actions, initialState);
export { Provider, Context };
