import React from 'react';
import { Context as LoaderContext } from "../../context/loader.context";
import { Box, CircularProgress } from '@material-ui/core';

export default function FullscreenLoader({ children }) {
    const Context = React.useContext(LoaderContext);

    const { state: { show = false } } = Context;
    return (
        <>
            {show && <Box style={{ zIndex: 999, height: '100%', width: '100%', position: 'fixed', left: 0, top: 0, background: '#ffff', textAlign: 'center' }}>
                <CircularProgress color="primary" style={{ position: 'relative', top: '50%' }} />
            </Box>}
            <div style={{ display: show ? 'none' : 'inherit' }}>
                {children}
            </div>
        </>
    )
}
