import { makeStyles, Slide, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { Context as SnackBarContext } from "../../context/snackbar.context";

const useStyle = makeStyles((theme) => ({
  anchorOriginTopCenter: {
    [theme.breakpoints.down("sm")]: {
      top: "25px",
      justifyContent: "center",
    },
  },
}));

function SnackbarComponent() {
  const toastContext = React.useContext(SnackBarContext);

  const {
    state: { open, message, type },
    closeSnackBar,
  } = toastContext;

  const classes = useStyle();

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => {
        closeSnackBar();
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      className={classes.anchorOriginTopCenter}
      TransitionComponent={Slide}
    >
      <Alert
        variant="filled"
        onClose={() => {
          closeSnackBar();
        }}
        severity={type || "error"}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarComponent;
