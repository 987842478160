import { capitalize } from "lodash";

const isRequiredCheck = (value, fieldname) => {
  const type = typeof value;
  if (type === "number") {
    if (value) {
      return "";
    } else return `${capitalize(fieldname)} cannot be empty.`;
  } else {
    if (value.trim().length > 0) {
      return "";
    } else return `${capitalize(fieldname)} cannot be empty.`;
  }
};
const isPasswordCheck = (value, fieldname) => {
  const passReg= /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#?$%^*_:()])[a-zA-Z0-9!@#?$%^*_:()]{8,}$/ 
    if (value) {
      if(passReg.test(value)){
        return ""
      }else{
        return `${capitalize(fieldname)} must be at least 8 characters long and contain 1 alphabetical character, 1 numeric character, 1 special character ( !@#?$%^*_: ) and no spaces`;
      }
    }
    else
        return `${capitalize(fieldname)} cannot be empty.`;
}
const isPasswordMatch = (newpassword, cPassword) => {
  if (newpassword == cPassword) {
      return '';
  }
  else
      return `New password & Confirm Password should be same.`
}

const isEmailCheck = (value) => {
  const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email_regex.test(value) ? "" : "Enter a valid email address";
};

export { isRequiredCheck, isEmailCheck,isPasswordCheck,isPasswordMatch };
