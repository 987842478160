import React from "react";

function ContextTemplate(reducer, actions, initialState) {
  const Context = React.createContext();

  const Provider = (props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const boundActions = {};

    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider
        value={{
          state,
          ...boundActions,
        }}
      >
        {props.children}
      </Context.Provider>
    );
  };

  return { Provider, Context };
}

export default ContextTemplate;
