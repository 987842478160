class LocalStorageService {

    static getItem(key) {
        return localStorage.getItem(key);
    }

    static removeItem(key) {
        localStorage.removeItem(key);
    }

    static storeItem(key, value) {
        localStorage.setItem(key, value);
    }

    static clearStore() {
        localStorage.clear();
    }
}


export default LocalStorageService;