import palette from "../palette";

const MCheckbox = {
  root: {
    color: palette.common.black,
  },
  colorPrimary: {
    color: palette.primary.light,
  },
  colorSecondary: {
    color: palette.secondary.light,
  },
};

export default MCheckbox;
