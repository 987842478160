import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  platformDiv: {
    display: "flex",
    padding: "0.5rem 0rem",
    alignItems: "center",
  },
  platformTitle: {
    flex: "1",
    marginLeft: "0.5rem",
  },
  disableText:{
    opacity:"0.4"

  },
  heighlightPortal:{
fontWeight:"bold",
fontSize:"16px"
  }
}));
function PlatformComponent(props) {
  const classes = useStyles();
  const { img, classname, title,disabled ,onClick,heighlight} = props;
  return (
    <div className={ clsx(classes.platformDiv,disabled && classes.disableText,heighlight && classes.heighlightPortal) } onClick={onClick}>
      <div className={classname}>
        <img src={img} alt="logo.png" style={{ maxWidth: "100%" }} />
      </div>
      <span className={classes.platformTitle}>{title}</span>
    </div>
  );
}

export default PlatformComponent;
