const white = "#FFFFFF";
const black = "#000000";
const palette = {
  common: {
    white,
    black,
  },
  primary: {
    contrastText: "#fff",
    main: "#2F75B6",
    light: "#73B8E1",
    dark: "#223463",
  },
  secondary: {
    contrastText: "#fff",
    main: "#F89F58",
    light: "#F89F58",
    dark: "#F89F58",
  },
  error: {
    light: "#e57373",
    main: "#f44336",
    dark: "#d32f2f",
    contrastText: "#fff",
  },
  warning: {
    light: "#ffb74d",
    main: "#ff9800",
    dark: "#f57c00",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  info: {
    light: "#64b5f6",
    main: "#2196f3",
    dark: "#1976d2",
    contrastText: "#fff",
  },
  success: {
    light: "#81c784",
    main: "#4caf50",
    dark: "#388e3c",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    primary: black,
    secondary: white,
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
  },

  divider: "#dfdfdf",
};

export default palette;
