
import React from "react";
import AppRouter from "./core/app.router";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./style/theme";
import { CssBaseline } from "@material-ui/core";
import HttpService from "./services/http.service";
import SnackbarComponent from "./common/components/snackbar.component";
import LoaderComponent from "./common/components/fullscreenLoader.component";
import { Provider as SnackBarProvider } from "./context/snackbar.context";
import { Provider as LoaderProvider } from "./context/loader.context";
import { Provider as PortalProvider } from "./context/portal.context";
import { Provider as LaraFilterProvider } from "./context/larafilter.context";
import { Provider as TurnaroundFilterProvider } from "./context/turnaroundfilter.context";
import { Provider as ReportSortContext } from "./context/reportSort.context";
import SplashscreenComponent from "./common/components/splashscreen.component";
HttpService.httpInterceptors();
function App() {
  const [initialload, setInitialLoad] = React.useState(true);
 
  React.useEffect(() => {
    setTimeout(() => {
      setInitialLoad(false);
    }, 2000);
   
  }, []);

 
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <SnackBarProvider>
          <LoaderProvider>
            <PortalProvider>
              <LaraFilterProvider>
                <ReportSortContext>
                  <TurnaroundFilterProvider>
                    <LoaderComponent>
                      {initialload ? <SplashscreenComponent /> :<> <AppRouter /></>}
                      <SnackbarComponent />
                    </LoaderComponent>
                  </TurnaroundFilterProvider>
                </ReportSortContext>
              </LaraFilterProvider>
            </PortalProvider>
          </LoaderProvider>
        </SnackBarProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
